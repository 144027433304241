var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full relative flex flex-col sm:flex-row sm:space-x-12",class:!_vm.isCamo ? 'border rounded-lg p-4' : ''},[_c('div',[_c('trial-ratio',{attrs:{"is-camo":"","label":"LTV","helper":"LTV Rate","stat":"ltvcancel","percentage":""}})],1),(_vm.uniqMonths)?_c('div',{staticClass:"flex h-64 flex-grow divide-x divide-gray-100 overflow-x-auto"},[_vm._l((_vm.uniqMonths),function(month,index){return _c('div',{key:`month-${index}`,staticClass:"h-full flex flex-grow flex-col justify-center items-center"},[_c('div',{staticClass:"w-full text-xs pb-2 text-gray-400 text-center"},[_vm._v(" "+_vm._s(month.month)+" ")]),_c('div',{staticClass:"text-xs py-1 text-gray-400 items-center justify-center text-center w-full space-x-1 flex-col h-8"},[_c('div',[_vm._v("("+_vm._s(month.vals.previous || 0)+")")])]),_c('div',{staticClass:"flex-grow w-full"},[_c('div',{staticClass:"relative min-w-bar w-full bg-indigo-400",style:({ height: `${_vm.getHeight(month.vals.today)}%` })}),_c('div',{staticClass:"text-xs py-1 text-gray-500 items-center justify-center text-center w-full space-x-1 flex-col h-8"},[_c('div',[_vm._v(_vm._s(month.vals.today))])])]),_c('div',{staticClass:"flex-grow flex justify-end items-end"},[(month.vals.today && month.vals.previous)?_c('div',{staticClass:"text-xs",class:[
            month.vals.today > month.vals.previous
              ? 'text-green-400'
              : month.vals.today === month.vals.previous
              ? 'text-gray-400'
              : 'text-red-400'
          ],domProps:{"innerHTML":_vm._s(_vm.getChange(month.vals.today, month.vals.previous, month))}}):_vm._e()])])}),_vm._m(0)],2):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-full flex flex-col sticky bg-white z-20 relative right-0 justify-center italic text-left items-center text-xs text-gray-400"},[_c('div',{staticClass:"w-20 pb-2 px-2"},[_vm._v("Month")]),_c('div',{staticClass:"py-1"},[_vm._v("Last Month")]),_c('div',{staticClass:"flex-grow"}),_c('div',[_vm._v("% Change")])])
}]

export { render, staticRenderFns }