<template>
  <div
    id="reporting-table-grid"
    class="grid bg-gray-200 border rounded-lg overflow-hidden overflow-x-auto border-b-0 mb-6  "
    @mouseenter="hoveredTable = true"
    @mouseleave="hoveredTable = null"
  >
    <div
      class="grid grid-cols-7 text-center gap-1px"
      style="grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr)"
    >
      <div
        v-for="(header, index) in headers"
        :key="header"
        class="bg-white p-4 pb-0 text-xs uppercase font-mono text-gray-400 "
        :class="index === 0 ? 'sticky left-0 z-20' : ''"
      >
        {{ header }}
      </div>
    </div>
    <div
      v-for="row in analytics.coreData"
      @mouseenter="setHoveredRow(row.label)"
      @mouseleave="setHoveredRow()"
      :key="row.label"
      class="grid grid-cols-7 gap-1px bg-gray-200 border-b group"
      style="grid-template-columns: minmax(min-content, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr)"
    >
      <div
        class="bg-white p-2 px-2 sticky left-0 z-20  quick-transition space-x-2 leading-none text-xs flex items-center uppercase font-mono  "
        :class="[
          hoveredRow === row.label ? 'bg-gray-100 text-gray-600' : 'bg-white',
          hoveredTable && hoveredRow !== row.label && hoveredRow
            ? 'text-gray-200'
            : 'text-gray-400',
          isFavourite(row.key)
            ? hoveredTable && hoveredRow !== row.label && hoveredRow
              ? 'bg-white'
              : 'font-medium bg-yellow-50'
            : ''
        ]"
      >
        <div
          class="text-lg cursor-pointer hover:text-yellow-500"
          :class="
            isFavourite(row.key)
              ? hoveredTable && hoveredRow !== row.label && hoveredRow
                ? 'text-gray-200'
                : ' text-yellow-500'
              : ''
          "
          @click="toggleFavourite(row.key)"
        >
          <font-awesome-icon
            :icon="[isFavourite(row.key) ? 'fad' : 'fal', 'star']"
          />
        </div>
        <div
          :class="
            isFavourite(row.key)
              ? hoveredTable && hoveredRow !== row.label && hoveredRow
                ? 'text-gray-200'
                : 'text-gray-900'
              : ''
          "
        >
          {{ row.label }}
        </div>
      </div>
      <div
        v-for="(data, index) in row.data"
        class="text-center quick-transition relative flex min-w-max items-center justify-center bg-white p-3 "
        :class="[
          hoveredRow === row.label ? 'bg-gray-100' : 'bg-white',
          hoveredTable && hoveredRow !== row.label && hoveredRow
            ? 'text-gray-200'
            : '',
          isFavourite(row.key)
            ? hoveredTable && hoveredRow !== row.label && hoveredRow
              ? 'bg-white'
              : 'font-medium bg-yellow-50'
            : ''
        ]"
        :key="`${row.label}-${index}`"
      >
        {{ data.value.toLocaleString()
        }}<span v-if="row.suffix">{{ row.suffix }}</span>
        <div
          v-if="data.change !== 0 && checkNumber(data.change)"
          class="absolute quick-transition text-xs font-mono -left-7  skew-x-2 transform w-14 bg-white rounded px-1 p-1   flex items-center justify-center leading-none"
          :class="[
            data.positive
              ? 'bg-green-100 text-green-500'
              : 'bg-red-100 text-red-500',
            hoveredTable && hoveredRow !== row.label && hoveredRow
              ? 'opacity-0'
              : ''
          ]"
        >
          <!-- <div class="text-base leading-none">
            {{ data.positive ? `&uarr;` : '&darr;' }}
          </div> -->
          {{ Math.abs(data.change) }}%
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { getMonth } from 'date-fns';
import utils from '@/utils';
export default {
  name: 'ReportingTable',
  computed: {
    ...mapGetters({
      analytics: 'analyticsHistory6MonthsGetter',
      userSettings: 'userSettingsGetter'
    }),
    headers() {
      const headers = this.analytics
        ? [
            '',
            ...this.analytics.analyticsCore.map(item => {
              return `${item.label} ${item.year}`;
            })
          ]
        : [];

      return headers;
    }
  },
  data() {
    return {
      coreData: [],
      analyticsCore: [],
      hoveredRow: null,
      hoveredTable: null,
      timeout: null
    };
  },
  methods: {
    ...mapActions({
      getHistoricalAnalytics: 'getHistoricalAnalytics'
    }),
    ...mapMutations({
      set6Months: 'set_6_months_analytics_stats',
      setUserSettings: 'set_user_settings'
    }),
    setHoveredRow(row) {
      if (row) {
        this.timeout = setTimeout(() => {
          this.hoveredRow = row;
        }, 500);
      } else {
        clearTimeout(this.timeout);
        this.hoveredRow = null;
      }
    },
    isFavourite(key) {
      const settings = this.userSettings;
      const config = settings['reportingTable'] || [];
      return config.includes(key);
    },
    toggleFavourite(item) {
      //set a local storage item to store the favourite items in an array, if the item is already in remove it if not add it
      const key = 'reportingTable';

      const settings = this.userSettings;

      const config = settings[key] || [];

      const index = config.indexOf(item);
      if (index > -1) {
        config.splice(index, 1);
      } else {
        config.push(item);
      }
      settings[key] = config;
      this.setUserSettings(settings);
    },
    getAnalytics() {
      const endDate = new Date();
      endDate.setDate(1);
      // get the start date that is 6 months before the end date
      const startDate = new Date();
      startDate.setMonth(endDate.getMonth() - 6);
      this.getHistoricalAnalytics({
        start: startDate,
        end: endDate
      }).then(response => {
        const data = response.data.content;
        // for each item in data work out the month and year and add it to the object as a new property, format the month to be the name of the month
        data.forEach(item => {
          const date = new Date(item.date);

          // set the item month as a number
          item.month = date.getMonth() + 1;
          item.label = date.toLocaleString('default', { month: 'short' });
          item.year = date.getFullYear();
        });
        // group all the items that have the same label and year together
        const grouped = data.reduce((acc, item) => {
          const key = `${item.label}`;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(item);
          return acc;
        }, {});

        const groupedArray = Object.keys(grouped).map(key => {
          return {
            label: key,
            start: grouped[key][0],
            end: grouped[key][grouped[key].length - 1],
            year: grouped[key][0].year
          };
        });

        // remove the last from the grouped array
        groupedArray.pop();
        this.analyticsCore = groupedArray;
        const rows = [
          {
            key: 'totaltrialboxes',
            label: 'Taster Boxes Sent'
          },
          {
            key: 'newsubscribersMtd',
            label: 'New Subscribers'
          },
          {
            key: '30Dayrollingtrialconversion',
            label: 'Taster Box Conversions',
            suffix: '%'
          },
          {
            key: 'totalSubscribers',
            label: 'Total Subscribers'
          },
          {
            key: 'totalboxes',
            label: 'Subscription Boxes Sent'
          },
          {
            key: 'cancellationsMtd',
            label: 'Cancelled Subscribers'
          },
          {
            key: 'avglengthcancel',
            label: 'Avg Months Subscribed'
          },
          {
            key: 'averageconversiondays',
            label: 'Avg Days to Convert'
          },
          {
            key: 'subscriptionsWithDryFood',
            label: 'Subscriptions Incl. Dry'
          },
          {
            key: 'freesamplesUiDcount60',
            label: 'Free Samples Sent'
          }
        ];

        const core = [];

        // for each of the rows, get the data for the row and add it to the data array
        rows.forEach(row => {
          const rowArray = [];
          groupedArray.forEach((group, index) => {
            const change = index
              ? `${(
                  ((group.end.data[row.key] -
                    groupedArray[index - 1].end.data[row.key]) /
                    groupedArray[index - 1].end.data[row.key]) *
                  100
                ).toFixed(1)}`
              : 0;

            rowArray.push({
              value: group.end.data[row.key] || '-',
              // work out percentage change based on the prevous group index
              change,
              // check if change is a positive number
              positive: change > 0
            });
          });
          core.push({
            label: row.label,
            key: row.key,
            suffix: row.suffix,
            data: rowArray
          });
        });

        this.coreData = core;

        const storeData = {
          lastChecked: getMonth(new Date()),
          coreData: this.coreData,
          analyticsCore: this.analyticsCore
        };
        utils.setStorage('historicalAnalytics6Months', storeData, 'local');
        this.set6Months(storeData);
      });
    },
    checkNumber(value) {
      return Number(value);
    }
  },
  mounted() {
    if (this.analytics) {
      this.$store.dispatch('checkHistory', this.analytics).then(() => {
        this.getAnalytics();
      });
    } else {
      this.getAnalytics();
    }
  }
};
</script>

<style></style>
