var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full group"},[_c('div',{staticClass:"w-full space-y-4 h-full flex-col flex relative",class:{
      'rounded-lg p-4 border': !_vm.isCamo
    }},[_c('div',{staticClass:"font-semibold default-transition text-lg items-center flex space-x-4",class:{
        'opacity-5': _vm.showHelper,
        'opacity-100': !_vm.showHelper
      }},[_c('div',{staticClass:"truncate flex flex-grow items-center space-x-2"},[_c('div',{staticClass:"truncate"},[_vm._v(_vm._s(_vm.label))]),(_vm.hasHelper)?_c('span',{staticClass:"hidden -top-1 group-hover:flex cursor-pointer hover:text-gray-600 text-gray-500",on:{"mouseover":_vm.showHelperAction,"mouseout":_vm.hideHelperAction}},[_c('font-awesome-icon',{staticClass:"text-sm",attrs:{"fixed-width":"","icon":['fal', 'circle-info']}})],1):_vm._e()]),(_vm.statCompare.formatted)?_c('div',{staticClass:"hidden text-base text-gray-400 font-normal group-hover:flex"},[_vm._v(" ("+_vm._s(_vm.statCompare.formatted)+") ")]):_vm._e()]),(_vm.hasStat)?_c('div',{staticClass:"flex-grow flex flex-col"},[_c('div',{staticClass:"default-transition",class:{
          'opacity-5': _vm.showHelper,
          'opacity-100': !_vm.showHelper
        }},[_c('div',{staticClass:"text-3xl font-semibold flex space-x-2 items-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.statNow.formatted || '-')+" "),(_vm.suffix && _vm.statNow.formatted)?_c('span',{staticClass:"text-lg"},[_vm._v(_vm._s(_vm.suffix))]):_vm._e()]),(_vm.statChange)?_c('div',{staticClass:"text-xs"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.statChange)}})]):_vm._e()]),_c('div',{staticClass:"text-gray-500 font-mono text-xs mt-1"},[_vm._v(" "+_vm._s(_vm.helper)+" ")])]),_c('transition',{attrs:{"enter-active-class":"miniFadeInUp","leave-active-class":"miniFadeOutDown","mode":"out-in"}},[(_vm.showHelper)?_c('div',{staticClass:"absolute bottom-2 right-2 left-2 p-2 pb-1 font-mono text-gray-600 rounded text-xs"},[_vm._v(" "+_vm._s(_vm.hasHelper.description)+" ")]):_vm._e()])],1):_c('div',{staticClass:"flex items-center flex-grow justify-center"},[_c('div',{staticClass:"p-1 text-sm bg-gray-50 italic text-gray-400 px-2 rounded"},[_vm._v(" No data available ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }